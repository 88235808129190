import React, { useState } from "react";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    message: ""
  });

  const updateField = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = e => {
    e.preventDefault();

    console.log(JSON.stringify(formData));

    fetch("http://staging.slspanama.com/mail/mail.php", {
      method: "post",
      // body: JSON.stringify(formData)
      body: formData
    })
      .then(res => res.json())
      .then(res => setFormData({ ...formData, result: JSON.stringify(res) }));
  };
  return (
    <div>
      <p>The contact form</p>

      <form action="http://staging.slspanama.com/mail/mail.php" method="post">
        <label htmlFor="fname" className="minerva uppercase block text-lg">
          First Name:
          <input
            value={formData.fname}
            onChange={updateField}
            type="text"
            name="fname"
            id="fname"
            required="required"
            placeholder="First Name"
            className="block border border-gray-600 px-3 py-2 outline-none mt-2"
          />
        </label>

        <label htmlFor="lname" className="minerva uppercase block text-lg mt-5">
          Last Name
          <input
            value={formData.lname}
            onChange={updateField}
            type="text"
            name="lname"
            required="required"
            placeholder="Last Name"
            className="block border border-gray-600 px-3 py-2 outline-none mt-2"
          />
        </label>

        <label
          htmlFor="email"
          className="minerva uppercase block text-lg  mt-5"
        >
          Email
          <input
            value={formData.email}
            onChange={updateField}
            type="text"
            name="email"
            required="required"
            placeholder="Your Email"
            className="block border border-gray-600 px-3 py-2 outline-none mt-2"
          />
        </label>

        <label htmlFor="phone" className="minerva uppercase block text-lg mt-5">
          Phone Number
          <input
            value={formData.phone}
            onChange={updateField}
            type="text"
            name="phone"
            required="required"
            placeholder="Phone Number"
            className="block border border-gray-600 px-3 py-2 outline-none mt-2"
          />
        </label>

        <label
          htmlFor="message"
          className="minerva uppercase block text-lg mt-5"
        >
          Your Message
          <textarea
            value={formData.message}
            onChange={updateField}
            name="message"
            required="required"
            className="block border border-gray-600 px-3 py-2 outline-none mt-2"
          ></textarea>
        </label>

        <button
          type="submit"
          className="minerva uppercase px-3 py-2 bg-gray-700 text-white mt-4 rounded-sm"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactPage;
